.button {
    height: 4rem;
    padding: 0 2rem;
    border: 0;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-items: center;
    text-decoration: none;
    line-height: 4rem;
    text-align: center;
    border-radius: 2rem;
    background-color: $purple;
    color: #FFF;
    font-weight: bold;
    cursor: pointer;

    &--outline {
        background-color: #FFF;
        border: 1px solid #DDD;
        color: $purple;
    }

    &--icon-before {
        i, svg {
            margin-right: 1rem;
        }
    }

    &--icon {
        width: 4rem;
        padding: 0;

        i, svg {
            width: 4rem;
            text-align: center;
            line-height: 4rem;
        }
    }
    
    &--red {
        color: #FFF;
        background-color: $red;
    }
}
