.spotlight {
  /*scroll-snap-type: x mandatory;
  scroll-padding: 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-bottom: 2rem;
  max-width: 1200px;
  margin: 0 auto;*/
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  width: 80%;
  margin: 0 auto;

  &__item {
    scroll-snap-align: start;
    width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    @media (orientation: portrait) {
      //min-width: 25%;
      //max-width: 25%;
    }

    img {
      max-width: 100%;
      max-height: 12rem;
    }
  }
}
