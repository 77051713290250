@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

$red: #FF5400;
$purple: #5A52A2;
$green: #59A21D;

@mixin boxShadow {
  box-shadow: 0 10px 20px rgba($purple, .1);
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #64A8F2;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #B3AFB3;
}

::-webkit-scrollbar-track {
  background: #F0F0F0;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: relative;
  user-select: none;
  font-family: 'Open Sans', sans-serif;
}

html, body {
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  position: relative;
}

.app {
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  flex-direction: column;

  &__holder {
    height: 100%;
    width: 100%;
    display: flex;
    //align-items: center;
    //justify-content: center;
    overflow-y: auto;
  }

  &__content {
    width: 100%;
    padding: 2rem;
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 2rem;

  img {
    height: 5rem;
    width: 10rem;
  }

  span {
    width: 10rem;

    &.app__header__buttonholder {
      width: 4rem;
    }
  }
}

p {
  font-size: 1.25rem;
}

.spacer {
  height: 2rem;
  width: 100%;
  display: block;
}

.page__content {
  text-align: center;

  &--scan {
    height: 100%;
  }
}

.label {
  display: inline-block;
  padding: .25rem .5rem;

  &--success {
    background-color: $green;
    color: #FFF;
  }

  &--danger {
    background-color: $red;
    color: #FFF;
  }
}

.game__detail {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  padding: 0 0 2rem 0;

  @media (orientation: portrait) {
    flex-direction: column;
  }

  @media (orientation: landscape) {
    flex-direction: row;
  }

  &__image {
    @media (orientation: landscape) {
      width: 50%;
    }

    text-align: center;
    margin-bottom: 4rem;

    img {
      max-width: 50%;
      max-height: 30rem;

      @media (orientation: landscape) {
        max-width: 90%;
        max-height: 90%;
      }
    }
  }

  &__content {
    @media (orientation: landscape) {
      width: 50%;
    }

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }

    h1, p {
      margin-bottom: 1rem;
    }

    table {
      border-collapse: collapse;
      margin: 4rem 0 0 0;
      padding: 0;
      text-align: left;
      width: 100%;
      font-size: 1rem;

      tr {
        td, th {
          border: 1px solid #DDD;
          padding: .5rem 1rem;
        }
      }
    }
  }

  &__price {
    color: $purple;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    display: block;
  }
}

.results {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  &__item {
    @include boxShadow;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    color: inherit;
    margin-bottom: 1rem;
    cursor: pointer;

    &__image {
      margin-right: 1rem;
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 5rem;
        max-height: 5rem;
      }
    }
  }
}

.question {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 4rem;
  }

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  &__item {
    position: relative;
    @include boxShadow;
    border-radius: 8px;
    border: 1px solid rgba($purple, .1);
    font-size: 1.25rem;
    margin-bottom: 1rem;
    cursor: pointer;

    a, span {
      color: #000;
      text-decoration: none;
      padding: 1rem;
      display: block;
    }
  }
}

.choices {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  justify-content: space-between;
  align-items: center;

  @media (orientation: portrait) {
    flex-direction: column;
  }

  &__item {
    background-color: #FFF;
    @include boxShadow;
    border-radius: 4px;
    padding: 3rem;
    width: 48%;
    text-align: center;
    color: inherit;
    text-decoration: none;
    margin: 1rem;
    cursor: pointer;

    @media (orientation: portrait) {
      width: 100%;
    }

    i, svg {
      font-size: 3rem;
      color: $red;
    }

    h1 {
      font-size: 1.5rem;
    }

    h1, p, i, svg {
      margin: 0 0 1rem 0;
    }

    p {
      opacity: .5;
      font-size: 1rem;
    }
  }
}

.search {
  &__input {
    border: 1px solid #DDD;
    height: 4rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
      margin: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      height: 100%;
      width: 100%;
      font-size: 1rem;
      border: 0;
      background: none;
      outline: none
    }
  }
}

.scan {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__icon {
    align-items: center;
    display: flex;
    /* height: 30rem; */
    justify-content: center;
    margin-bottom: 5rem;

    svg {
      height: 10rem;
      width: 10rem;
      color: $purple;

    }
  }

  &__image {
    margin-top: 5rem;
    width: 30rem;
  }
}

.keyboard {
  &__hidden {
    display: none;
  }
}

@import "spotlight";
@import "buttons";
